<template>

  <el-container>

    <el-header height="220">


      <el-row>
        <el-input v-model="queryInfo.occupation" @blur="contentChange" placeholder="申报职业"
                  style="margin-left: 5px;width: 217px"
                  prefix-icon="el-icon-search"></el-input>

      </el-row>



      <el-row style="margin-top: 10px" v-model="operation">
        <el-button type="primary" icon="el-icon-plus" @click="addOccupationVisible = true">添加</el-button>

        <div align="right">
          <router-link to="/allUserManager" ><i class="el-icon-s-promotion">已完成报名人员</i></router-link>
        </div>

      </el-row>





    </el-header>

    <el-main>
      <!--操作的下拉框-->
      <el-select  clearable v-if="selectionTable.length !== 0" v-model="operation"
                 :placeholder="'已选择' + selectionTable.length + '项'" style="margin-bottom: 25px;">

        <!--        <el-option v-for="(item,index) in optionInfo" :key="index" :value="item.desc">
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right; color: #ffffff; font-size: 13px">{{ item.desc }}</span>
                </el-option>-->

      </el-select>

      <el-table
        ref="occsTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="occsInfo"
        tooltip-effect="dark"
        style="width: 100%;"

        @selection-change="handleTableSectionChange">

        <el-table-column align="center"
                         type="selection"
                         width="55">
        </el-table-column>


        <el-table-column align="center" prop="occupation"
                         label="申报职业">
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope" >
            <el-tooltip class="item" effect="dark" content="修改信息" placement="top-start">
            <el-button size="mini" icon="el-icon-edit"
                       type="warning"
                       @click="editOcc(scope.row.id)"></el-button></el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除信息" placement="top-start">
            <el-button size="mini" icon="el-icon-delete"
                       type="danger"
                       @click="open(scope.row.id)"></el-button></el-tooltip>
          </template>
        </el-table-column>


      </el-table>

      <!--分页-->
      <el-pagination style="margin-top: 25px"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.pageNo"
                     :page-sizes="[10, 20, 30, 50]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>

    </el-main>

    <el-dialog title="添加申报职业" :visible.sync="addOccupationVisible" width="40%" @close="resetOccQuForm"  center>
      <el-card>
        <el-form :model="OccupationForm" ref="addOccForm" :rules="occupationFormRules">

          <el-form-item prop="occ" label="职业名称" label-width="120px" >
            <el-input type="input" :rows="6" placeholder="请输入申报职业" v-model="OccupationForm.occ" style="margin-left: 5px" ></el-input>

          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addOccupationVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddOcc">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="修改申报职业" :visible.sync="editOccupationVisible" width="40%" @close="resetOccQuForm1"  center>
      <el-card>
        <el-form :model="OccupationForm" ref="editOccForm" :rules="occupationFormRules">

          <el-form-item prop="occ" label="职业名称" label-width="120px" >
            <el-input type="input" :rows="6" placeholder="请输入申报职业" v-model="OccupationForm.occ" style="margin-left: 5px" ></el-input>

          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editOccupationVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </div>
    </el-dialog>




  </el-container>

</template>

<script>
import questionBank from '@/api/questionBank'
import question from '@/api/question'
import utils from '@/utils/utils'
import { generateSign } from '@/utils/sign'


export default {
  name: 'AllUserManage',


  data () {
    return {
      uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
      //查询用户的参数
      queryInfo: {
        //题目类型下拉款所选的内容
        'occupation': '',

        'pageNo': 1,
        'pageSize': 10
      },

      addOccupationVisible: false,
      editOccupationVisible:false,
      //题目信息
      occsInfo: [],
      //题目信息表格是否加载
      loading: true,
      OccupationForm: {
        occ:''
      },
      editOccInfo:{
        id:1,
        occ:'',

      },
      delOccInfo:{
        id:1,
        delFlag:2,
      },
      //表格被选中的所有行
      selectionTable: [],
      //表格行被选中后的所有操作方式的数据
      occupationFormRules:{
        occ: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'blur'
          }
        ]
      },
      //表格行被选中后的数据
      operation: '',
      //题目总数
      total: 0,

    }
  },
  created () {
    this.getOccInfo()



  },
  methods: {

    open(val) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delOcc(val)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    getOccInfo() {
      questionBank.getOccInfo(this.queryInfo).then((resp) => {
        if (resp.code === 200) {

          this.occsInfo = resp.data.data
          this.total = resp.data.total
          this.loading = false

        }
      })
    },
    edit(){
      this.$refs['editOccForm'].validate((valid) => {
        if(valid){
          this.editOccInfo.occ = this.OccupationForm.occ
          questionBank.editOcc(this.editOccInfo).then((resp)=>{
            if(resp.code===200)
            {
              if(resp.data == "职业已存在"){
                this.editOccupationVisible = false
                this.$message.error(resp.data)
                return;
              }
              this.editOccupationVisible = false
              this.getOccInfo()
              this.$notify({
                title: 'Tips',
                message: resp.data,
                type: 'success',
                duration: 2000
              })
            }

          })

        }
        else if (!valid)
        {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },
    editOcc(val){
      this.editOccInfo.id = val
      this.editOccupationVisible = true


    },
    delOcc(val){
      this.delOccInfo.id = val
      questionBank.editOcc(this.delOccInfo).then((resp)=>{
        if(resp.code===200)
        {
          this.getOccInfo()
          this.$notify({
            title: 'Tips',
            message: resp.data,
            type: 'success',
            duration: 2000
          })
        }

      })
    },

    resetOccQuForm(){
      this.$refs['addOccForm'].resetFields()
    },
    resetOccQuForm1(){
      this.$refs['editOccForm'].resetFields()
    },

    AddOcc(){
      this.$refs['addOccForm'].validate((valid) => {
        if (valid)
        {

          questionBank.addOccInfos(this.OccupationForm.occ).then((resp)=>
          {
            if(resp.code===200)
            {
              if(resp.data === "无需重复添加"){
                this.addOccupationVisible = false
                this.$message(resp.data)
                return;
              }
              this.addOccupationVisible = false
              this.getOccInfo()
              this.$notify({
                title: 'Tips',
                message: resp.data,
                type: 'success',
                duration: 2000
              })
            }

          })

        }
        else if (!valid)
        {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },

    //处理表格被选中
    handleTableSectionChange(val) {
      this.selectionTable = val
    },

    contentChange() {
      //发送查询题目总数的请求
      this.getOccInfo()
    },
    //分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getUserInfo()
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val
      this.getUserInfo()
    },
    //表单信息重置
    resetAddForm() {
      //清空表格数据
      this.$refs['addForm'].resetFields()
    },
    resetRemoveForm() {
      //清空表格数据
      this.$refs['removeForm'].resetFields()
    },




  },
  computed: {
    //监测头部信息的token变化
    headers() {
      const signHeaders = {
        'body-string': '',
        'query-string': '',
        'x-nonce': `${utils.getRandomId()}`,
        'x-timestamp': `${new Date().getTime()}`
      }
      return {
        ...signHeaders,
        authorization: localStorage.getItem('authorization') || '',
        sign: generateSign(JSON.stringify(signHeaders)),
      }
    },
  }

}
</script>

<style scoped lang="scss">
@import "../../assets/css/teacher/questionManage";
</style>

<style>
.el-icon-view{
  cursor: pointer; /* Change the mouse style to pointer (hand) */
  color: red;
}
</style>
